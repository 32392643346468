<template>
  <div class="head-container head-form-container shelves-header">
    <BaseForm customClass="customClass" ref="form" label-width="80px" :cols="formField" :form="query" @change="to">
      <template #enableSlot="{ scoped: { prop, placeholder } }">
        <el-select v-model="query[prop]" :placeholder="placeholder" @change="to">
          <el-option
            v-for="(item, index) in PROTO_GROUP_ENABLE_DIC"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>

      <template #doneSlot>
        <el-button size="small" type="primary" icon="el-icon-search" @click="to"></el-button>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import { PROTO_GROUP_ENABLE_DIC } from '@/utils/constant'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  mixins: [commonEheaderMixin],
  props: {
    formField: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      PROTO_GROUP_ENABLE_DIC
    }
  },

  watch: {
    'query.name': {
      handler(n) {
        if (validatenull(n)) {
          this.toQuery()
        }
      },
      deep: true
    },
    'query.styleName': {
      handler(n) {
        if (validatenull(n)) {
          this.toQuery()
        }
      },
      deep: true
    }
  },

  methods: {
    async to() {
      const [err, valid] = await awaitWrap(this.$refs.form.validate())
      if (!valid) return
      this.toQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.shelves-header {
  ::v-deep {
    .customClass {
      .el-form.el-form {
        padding: 0;
      }
      .el-form-item__label {
        white-space: nowrap;
      }
    }
  }
}
</style>
